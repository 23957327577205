import React from "react";
import { navItems } from "./utils";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import { Slide } from "react-reveal";

const ModalNavBar = props => {
  return (
    <Modal onClick={props.onClose}>
      {/* <div
        className={`${
          props.navAction
            ? " z-20 fixed top-0 left-0 w-full h-screen bg-softBlack flex items-start  justify-center py-8 px-4"
            : "hidden"
        }`}
        
      > */}
      <ul className="  flex flex-col text-meduimGrey  justify-center gap-4 items-center w-full h-full bg-white p-8 font-semibold rounded ">
        <button
          className=" absolute top-10 right-10 block text-meduimGrey  lg:hidden cursor-pointer"
          onClick={props.onClose}
        >
          {props.navAction ? <FontAwesomeIcon icon={faClose} /> : ""}
        </button>
        {navItems.map(nav =>
          nav.rout ? (
            <Slide left>
              <Link to={nav.rout} key={nav.id}>
                {nav.name}
              </Link>
            </Slide>
          ) : (
            <div className="w-1/2 flex flex-col items-center">
              <h6 className="text-black mb-8">{nav.name}</h6>
              <ul className="flex flex-col items-center">
                {nav.list.map(list => (
                  <li key={list.id} className="text-meduimGrey mb-4">
                    {list.name}
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </ul>
      {/* </div> */}
    </Modal>
  );
};

export default ModalNavBar;
