import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import HeroSection from "../components/HeroSection";
import back from "../image/back.png";
import dashboard from "../image/dash.png";
import Grid from "@material-ui/core/Grid";
import { partners, reasons, figures, footerItems } from "../components/utils";
import NavBar from "../components/NavBar";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import animationData from "../lottie/move.json";
import animationDataa from "../lottie/range.json";
import { Roll, Slide } from "react-reveal";
import developers from "../image/developers.jpg";
import CountUp from "react-countup";

import VisibilitySensor from "react-visibility-sensor";

const Home = () => {
  const { text } = useTypewriter({
    words: ["DESIGN", "DEVELOP", "DEPLOY"],
    loop: {},
    cursor: true,
  });

  return (
    <div className="relative">
      <div className=" h-screen/ md:h-screen relative flex items-center flex-col w-screenn first-line:bg-white">
        <HeroSection />
        <div className="absolute top-0 left-0 w-screen max-w-full pt-8 px-10  lg:px-52 md:px-32  z-20 ">
          <NavBar />
        </div>

        <div className=" absolute w-screen left-0 flex translate-y-0 md:translate-y-1/4 mx-10 lg:mx-52 md:mx-32 mt-10 md:mt-0 ">
          <div className="w-full lg:w-1/2  pt-12 lg:pt-32 z-0 ">
            <div className="my-4 md:mb-10 text-3xl md:text-5xl lg:text-7xl uppercase text-white font-bold animate-glow ">
              Let us <span className="text-secondary  ">{text}</span>
              <Cursor />
            </div>
            <div className="text-3xl md:text-5xl lg:text-7xl uppercase text-white font-bold animate-glow ">
              Your Platform
            </div>
            <div className="text-1xl text-white md:text-meduimGrey mt-10 w-4/5">
              We have been helping companies and established brands reimagine
              their business through infrastructure and platforms
            </div>
            <button className="  bg-meduimGrey text-1xl text-white rounded-full px-7 py-3 my-5">
              <Link to="/contact"> Contact Us</Link>
            </button>
          </div>
          <div className=" w-1/2 -z-10 relative hidden md:block  ">
            <div className="absolute -left-16 ">
              <img src={back} className="z-10 translate-y-16" />
            </div>
            <div className="absolute -z-10 translate-x-14">
              <img src={dashboard} loading="lazy" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-lightGrey -mt-18 py-20 md:py-32   px-10 lg:px-52 md:px-32">
        <h3 className=" text-1xl md:text-2xl mb-10 text-tertiary font-bold ">
          Reliable Team
        </h3>
        <div className="flex flex-col md:flex-row w-full justify-between ">
          <div className="flex flex-col mb-4 items-center md:w-1/2  w-full">
            <Roll>
              <h2 className="w-full  text-2xl md:text-4xl mb-10 font-bold text-secondary ">
                We have a qualified and dedicated team that will meet your
                business objective
              </h2>
            </Roll>
            <div className="flex flex-col items-center gap-6 w-full">
              <Roll>
                <div className="w-full">
                  To create great software solutions, we combine our technical
                  experience and industry knowledge. We have shown to be a
                  <span className="text-tertiary font-semibold">
                    {" "}
                    trusted outsourcing partner
                  </span>
                  for companies aiming to boost their growth through software
                  development by focusing on quality-driven results.
                </div>
              </Roll>
            </div>
          </div>
          <Roll>
            <div className="w-full md:w-1/2 bg-tertiary shadow-lg shadow-meduimGrey p-1 rounded-md object-cover ">
              <img src={developers} className="object-cover w-full h-full" />
            </div>
          </Roll>
        </div>

        <div className="flex items-center justify-center md:items-start md:justify-start">
          <button className="bg-meduimGrey text-1xl text-white rounded-full px-7 py-3 my-5">
            <Link to="/contact"> Get Started</Link>
          </button>
        </div>
      </div>
      <div className="">
        <div className="h-auto w-screen clip-path-myslant bg-secondary py-64 px-10 lg:px-52 md:px-32">
          <h2 className="text-skyBlue text-1xl md:text-2xl font-bold mb-4">
            We Offer a wide Range of Services
          </h2>
          <div className="flex flex-col md:flex-row w-full items-center">
            <div className="w-full flex flex-col gap-6 md:w-1/2">
              <h1 className="text-white font-bold text-2xl md:text-4xl ">
                We create products and solution tailored to your needs
              </h1>
              <p className="text-softGrey">
                We provide end-to-end software engineering services, as well as
                on-demand teams and resources for full-scale enterprise digital
                transformation and product creation.
              </p>
              <div className="flex items-center justify-center md:items-start md:justify-start">
                <button className="bg-skyBlue text-1xl text-secondary rounded-full px-7 py-3 my-5">
                  <Link to="/contact">Get Started </Link>
                </button>
              </div>
              <div>
                <div className="flex flex-col md:flex-col lg:flex-row">
                  <div>
                    <h4 className="font-semibold text-white border-l-2 border-skyBlue pl-4 ">
                      Easy to use Solutions
                    </h4>
                    <p className="text-softGrey pl-4">
                      We build state of art and user-friendly platforms
                    </p>
                  </div>
                  <div>
                    <h4 className="font-semibold text-white  border-l-2 border-skyBlue pl-4">
                      Custom made Solution
                    </h4>
                    <p className="text-softGrey pl-4">
                      We listen to your preferences and build products that fit
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden md:flex w-full md:w-1/2">
              {" "}
              <Lottie animationData={animationDataa} autoPlay loop />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-12 py-32   px-10 lg:px-52 md:px-32">
        <h2 className="w-full text-2xl md:text-4xl mb-10 font-bold text-secondary md:w-4/5">
          Why Radeus
        </h2>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
          {reasons.map(part => (
            <Slide left>
              <div>
                <h6 className="text-black font-semibold mb-4 pl-4 border-l-2 border-tertiary">
                  {part.title}
                </h6>

                <div className="text-meduimGrey ">{part.description}</div>
              </div>
            </Slide>
          ))}
        </div>
      </div>
      <div>
        <div className=" relative h-auto w-screen clip-path-myslope bg-secondary pt-44 md:py-44 lg:pt-44 lg:pb-24  px-10 lg:px-52  md:px-32 md:h-auto lg:h-[100vh]">
          <div className="w-full mb-16 md:w-1/2">
            <h2 className="text-skyBlue text-1xl md:text-2xl font-bold mb-4">
              Global Scale
            </h2>
            <Lottie
              animationData={animationData}
              autoPlay
              loop
              className="hidden md:block absolute md:top-44 lg:top-52 lg:right-14 w-1/2 h-1/2"
            />

            <h1 className="text-white  font-bold text-2xl md:text-4xl ">
              Delivers cutting edge solutions for global organisations and
              technology startups.
            </h1>
            <p className="text-softGrey">
              Over the years, we have been helping companies and established
              brands reimagine their business through
              <span className="text-1xl text-skyBlue font-bold ml-1">
                infrastructure and platforms.
              </span>
            </p>
          </div>

          <Grid container spacing={2}>
            {figures.map(part => (
              <Grid item md={3} sm={6} xs={12} key={part.id}>
                <div className="flex md:flex-col">
                  <h6 className="text-white font-bold text-4xl mb-4   border-l-2 border-skyBlue pl-4">
                    <CountUp start={0} end={part.number} enableScrollSpy />
                  </h6>
                  <p className="text-softGrey text-lg ">{part.description}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      <div id="partners" className=" mt-8 mx-10 lg:mx-52 md:mx-32 md:mt-10 ">
        <h2 className="w-full text-2xl md:text-4xl mb-10 font-bold text-secondary md:w-4/5">
          We are trusted by these global businesses and more.
        </h2>
        <div className="grid gap-4 grid-cols-2 md:grid-cols-4 justify-center">
          {partners.map(part => (
            <Roll>
              <div
                key={part.id}
                className="text-center aspect-square w-32 md:w-40 bg-meduimLightGrey rounded-lg"
              >
                <img src={part.img} className="w-full h-full object-contain" />
              </div>
            </Roll>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
