import React, { useRef, useState, useEffect } from "react";
import Modal from "../components/Modal";
import NavBar from "../components/NavBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faLocationPin,
  faCalendarDays,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faGoogle,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import Lottie from "lottie-react";
import animationData from "../lottie/contactf.json";
import { Slide } from "react-reveal";

const ContactModal = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [error, setError] = useState(false);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const serviceRef = useRef(null);
  const textRef = useRef(null);
  const submitFormHandler = () => {
    const name = nameRef.current.value;
    const email = emailRef.current.value;
    const phone = phoneRef.current.value;
    const enquiry = textRef.current.value;
    const service = serviceRef.current.value;

    if (
      name.trim().length === 0 &&
      email.trim().length === 0 &&
      phone.trim().length === 0 &&
      enquiry.trim().length === 0 &&
      service.trim().length === 0
    ) {
      return;
    } else {
      setError(false);
    }
  };

  const { text } = useTypewriter({
    words: ["Contact Us", "Contact Us", "Contact Us"],
    loop: {},
    cursor: true,
  });

  return (
    <div className="relative">
      <Lottie
        animationData={animationData}
        autoPlay
        loop
        className="absolute hidden md:block -right-10 top-20 w-2/5 z-10"
      />
      <div className="flex flex-col gap-16 justify-center h-screen/2 md:h-screen/ w-screen clip-path-mypolygonSmall lg:clip-path-mypolygon bg-secondary transition-all ease-in-out duration-300 py:4 md:py-10  overflow-hidden  px-10 lg:px-52 md:px-32">
        <div className="absolute top-0 left-0 w-screen max-w-full pt-8  px-10 lg:px-52 md:px-32   ">
          <NavBar />
        </div>

        <div className="animate-glow mb-6 md:mb-10 text-3xl md:text-5xl lg:text-7xl uppercase text-white font-bold">
          {text} <Cursor />
        </div>
      </div>

      <div className="flex flex-col-reverse w-full md:-mt-12 px-4 lg:px-52 md:px-32 overflow-hidden md:flex-row">
        <div className="p-8 w-full flex flex-col items-center justify-around gap-8 md:w-1/2">
          <div className="">
            <h3 className="text-2xl text-secondary text-center mb-4">
              Contact Us
            </h3>
            <p className="text-meduimGrey text-center">
              We’ll be in touch to kick things off in no time
            </p>
          </div>
          <div className="flex flex-col  justify-center items-center gap-6 md:flex-row md:items-start md:justify-start ">
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faLocationPin} />{" "}
            </div>
            <h6 className="text-meduimGrey">
              50 Tapa Street, Ebute Metta, Yaba Lagos
            </h6>
          </div>
          <div className="flex flex-col justify-center items-center gap-6 md:flex-row md:items-start md:justify-start">
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faCalendarDays} />
            </div>
            <h6 className="text-meduimGrey">Weekdays ...... 24 Hours</h6>
          </div>
          <div className="flex flex-col justify-center items-center gap-6 md:flex-row md:items-start md:justify-start">
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faPhone} />
            </div>
            <h6 className="text-meduimGrey">+234 814 376 1476</h6>
          </div>
          <div className="flex flex-col justify-center items-center gap-6 md:flex-row md:items-start md:justify-start">
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <a
              href="mailto:contact@radeusbusiness.com"
              className="text-meduimGrey"
            >
              contact@radeusbusiness.com
            </a>
          </div>
          <div>
            <h5 className="text-1xl text-secondary text-center mb-4">
              Follow Us
            </h5>
            <div className="flex gap-6">
              <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
                <FontAwesomeIcon icon={faFacebook}>
                  {" "}
                  <a
                    href="https://m.facebook.com/radeusbusiness/"
                    target="_blank"
                  />
                </FontAwesomeIcon>
              </div>
              <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
                <FontAwesomeIcon icon={faTwitter}>
                  {" "}
                  <a
                    href="https://twitter.com/radeusbusiness"
                    target="_blank"
                  />
                </FontAwesomeIcon>
              </div>
              <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
                <FontAwesomeIcon icon={faGoogle}>
                  <a href="https://g.co/kgs/BAzoG5" target="_blank" />
                </FontAwesomeIcon>
              </div>
              <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
                <FontAwesomeIcon icon={faInstagram}>
                  <a
                    href="https://instagram.com/radeusbusiness"
                    target="_blank"
                  />
                </FontAwesomeIcon>
              </div>
              <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
                <FontAwesomeIcon icon={faLinkedin}>
                  <a
                    href="https://www.linkedin.com/company/radeusbusiness/
                    "
                    target="_blank"
                  />
                </FontAwesomeIcon>
              </div>
            </div>
          </div>
        </div>
        <form
          onSubmit={submitFormHandler}
          className="flex w-full flex-col gap-6 p-8 md:w-1/2"
        >
          <div className="text-2xl text-secondary text-center">
            Make an Enquiry
          </div>
          {error && (
            <div className="text-sm text-red-800 text-center">
              Kindly fill all fields
            </div>
          )}
          <input
            placeholder="Name"
            name="name"
            ref={nameRef}
            type="text"
            className="bg-lightGrey border border-softGrey h-12 p-4 rounded-sm focus:outline-none"
          />
          <input
            name="email"
            type="email"
            ref={emailRef}
            className=" bg-lightGrey border border-softGrey h-12 p-4 rounded-sm focus:outline-none"
            placeholder="Email"
          />
          <input
            placeholder="Phone"
            name="phone"
            type="text"
            ref={phoneRef}
            className=" bg-lightGrey border border-softGrey h-12 p-4 rounded-sm focus:outline-none"
          />
          <select
            name="services"
            id="services"
            ref={serviceRef}
            placeholder="Select required services"
            className="text-dimGrey bg-lightGrey border border-softGrey h-14 p-4 rounded-sm focus:outline-none"
          >
            {" "}
            <option value="">Select Required Services</option>
            <option value="Bespoke Solution Development">
              Bespoke Solution Development
            </option>
            <option value="Mobile App Development">
              Mobile App Development
            </option>
            <option value="IT Trainings">IT Trainings</option>
            <option value="Infrastructure Development">
              Infrastructure Development
            </option>
            <option value="Enterprise Solution Development">
              Enterprise Solution Development
            </option>
            <option value="IT Consultancy">IT Consultancy</option>
          </select>
          <textarea
            placeholder="Additional Message"
            name="message"
            rows={7}
            ref={textRef}
            className="bg-lightGrey border border-softGrey  p-4 rounded-sm focus:outline-none"
          />
          <div className="flex items-center justify-center ">
            <button
              className="bg-tertiary font-bold text-1xl text-white hover:bg-transparent hover:text-tertiary rounded-full px-7 py-3 my-5"
              type="submit"
            >
              Send Message
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactModal;
