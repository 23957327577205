import React from "react";
import NavBar from "./NavBar";

const HeroSection = () => {
  return (
    <div className=" h-screen w-screen clip-path-mypolygonSmall lg:clip-path-mypolygon bg-secondary  transition ease-in-out duration-5000  py:4 md:py-10 px-4 lg:px-52 md:px-32  "></div>
  );
};

export default HeroSection;
