import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { Fade } from "react-reveal";

const Backdrop = props => {
  return (
    <div
      className="fixed top-0 left-0 w-full h-screen bg-softBlack"
      onClick={props.onClose}
    />
  );
};
const ModalOverlay = props => {
  return (
    <div className=" fixed top-10 right-1/2 translate-x-1/2  w-5/6 h-screen/2 bg-white p-4 rounded-2xl z-30 md:w-3/4 xl:w-1/2">
      <Fade>
        <div className="flex items-center justify-center h-full">{props.children}</div>
      </Fade>
    </div>
  );
};
const portalElement = document.getElementById("overlays");
const Modal = props => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay>{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};
export default Modal;
