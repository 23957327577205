import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { figures, partners, size, stack } from "../components/utils";
import Grid from "@material-ui/core/Grid";
import Lottie from "lottie-react";
import animationData from "../lottie/compan.json";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import office from "../image/office.jpg";
import Typewriter from "typewriter-effect";
import { Fade, Zoom, Rotate, Reveal, Slide } from "react-reveal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";

const Company = () => {
  const [show, setShow] = useState(false);
  const { text } = useTypewriter({
    words: ["COMPANY", "COMPANY", "COMPANY"],
    loop: {},
    cursor: true,
  });

  const handleClick = () => {
    setShow(true);
  };

  useEffect(() => {
    handleClick();
  }, []);
  // const { reasons } = useTypewriter({
  //   words: [
  //     "From direct integrations with card networks and banks to checkout flows in the browser",
  //     "We release hundreds of features and improvements each year to help you stay ahead",
  //     "Result Oriented",
  //     "Our machine learning models train on billions of data points",
  //   ],
  //   typeSpeed: 100,
  //   loop: {},
  //   cursor: true,
  // });
  // const { why } = useTypewriter({
  //   words: ["From direct ", "We release ", "Our systems ", "Our machine "],

  //   loop: {},
  //   cursor: true,
  // });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="relative ">
      <Lottie
        animationData={animationData}
        autoPlay
        loop
        className="absolute hidden md:block -right-10 top-20 w-2/5 z-10"
      />
      <div className="flex flex-col gap-20 justify-center h-screen/2 md:h-screen/ w-screen clip-path-mypolygonSmall lg:clip-path-mypolygon bg-secondary transition-all ease-in-out duration-300 py:4 md:py-10 px-10 lg:px-52 md:px-32 overflow-hidden ">
        <div className="absolute top-0 left-0 w-screen max-w-full pt-8  px-10 lg:px-52 md:px-32   ">
          <NavBar />
        </div>

        <div className="animate-glow mb-6 md:mb-10 text-3xl md:text-5xl lg:text-7xl uppercase text-white font-bold">
          {text} <Cursor />
        </div>
      </div>
      <div
        id="about"
        className="gap-8 flex flex-col md:flex-row mt-10 px-10 lg:px-52 md:px-32"
      >
        <div className="w-full md:w-1/2">
          <h1 className="w-full text-2xl md:text-4xl mb-10 font-bold text-secondary ">
            Who We Are
          </h1>
          <h3 className="w-full text-1xl md:text-2xl mb-10 font-bold text-secondary ">
            Adaptive, Responsive and Result Oriented.
          </h3>
          <p className="text-meduimGrey">
            We are a digital engineering company that delivers cutting edge
            solutions for global organisations and technology startups. Over the
            years, we have been helping companies and established brands
            reimagine their business through infrastructure and platforms
          </p>
        </div>
        <div className="w-full md:w-1/2">
          <img src={office} loading="lazy" />
        </div>
      </div>
      <div className="mt-20 px-10 lg:px-52 md:px-32">
        <div>
          <h2 className="w-full text-2xl md:text-4xl my-10 font-bold text-secondary">
            Why should you work with us?
          </h2>
          <p className="text-meduimGrey mb-8">
            There are many software development companies out there. What makes
            Radeus Business different? Why should you choose us?
          </p>

          <div className="grid gap-4 grid-cols-2 grid-rows-2">
            <Rotate top left when={show}>
              <div className="  text-secondary  text-sm  md:text-2xl capitalize text-center  h-44 flex items-center justify-center border  shadow-1xl rounded">
                <Typewriter
                  options={{
                    strings: [
                      "We value our customers",
                      "deep level of expertise",
                      "highest level of system",
                      "structure and competence",
                    ],
                    pauseFor: 4000,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </Rotate>
            <Rotate top right when={show}>
              <div className=" text-secondary text-sm  md:text-2xl capitalize text-center h-44  flex items-center justify-center border  shadow-2xl rounded">
                <Typewriter
                  options={{
                    strings: [
                      "deep level of expertise",
                      "highest level of system",
                      "We value our customers",
                      "structure and competence.",
                    ],
                    pauseFor: 4000,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </Rotate>
            <Rotate bottom right when={show}>
              <div className=" text-secondary text-sm  md:text-2xl capitalize text-center h-44  flex items-center justify-center border  shadow-3xl  rounded">
                <Typewriter
                  options={{
                    strings: [
                      "highest level of system",
                      "We value our customers",
                      "structure and competence.",
                      "deep level of expertise",
                    ],
                    pauseFor: 4000,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </Rotate>
            <Rotate bottom left when={show}>
              <div className=" text-secondary text-sm  md:text-2xl capitalize text-center h-44  flex items-center justify-center border shadow-4xl  rounded">
                <Typewriter
                  options={{
                    strings: [
                      "highest level of system",
                      "We value our customers",
                      "structure and competence.",
                      "deep level of expertise",
                    ],
                    pauseFor: 4000,
                    autoStart: true,
                    loop: true,
                  }}
                />
              </div>
            </Rotate>
          </div>

          {/* <ul className="list-disc">
            {figures.map(part => (
              <li className="text-secondary font-bold text-xl mb-4   ">
                {part.description}
              </li>
            ))}
          </ul> */}
        </div>
      </div>
      <div
        id="stack"
        className="h-auto w-screen clip-path-myslant bg-secondary py-80 px-10 lg:px-52 md:px-32"
      >
        <div className="grid grid-cols-1 gap-2 md:grid-cols-3 lg:grid-cols-4 justify-center">
          {stack.map(part => (
            <div key={part.id}>
              <Fade>
                <div className="text-center flex flex-col gap-6 text-white mb-3.5">
                  <div className="text-5xl text-softGrey">
                    {" "}
                    <FontAwesomeIcon icon={part.icon} />
                  </div>
                  <div className="text-3xl">{part.title}</div>
                  <div className="text-sm">{part.des}</div>
                </div>
              </Fade>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Company;
