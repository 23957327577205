import React from "react";
import logo from "../image/logo.svg";
import { Link } from "react-router-dom";

import { footerItems } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faTwitter,
  faFacebook,
  faGoogle,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { HashLink } from "react-router-hash-link";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="flex flex-col-reverse md:flex-row justify-around h-auto w-screen clip-path-myslope bg-lightGrey pt-72 pb-12  md:pt-44 px-10  md:px-32 md:h-auto lg:h-auto">
      <div className=" h-full flex flex-col-reverse justify-between md:h-1/2">
        <img src={logo} className="scale-50" />
        <div>
          <div className="flex gap-6 mt-8">
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faFacebook}>
                {" "}
                <a
                  href="https://m.facebook.com/radeusbusiness/"
                  target="_blank"
                />
              </FontAwesomeIcon>
            </div>
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faTwitter}>
                {" "}
                <a href="https://twitter.com/radeusbusiness" target="_blank" />
              </FontAwesomeIcon>
            </div>
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faGoogle}>
                <a href="https://g.co/kgs/BAzoG5" target="_blank" />
              </FontAwesomeIcon>
            </div>
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faInstagram}>
                <a
                  href="https://instagram.com/radeusbusiness"
                  target="_blank"
                />
              </FontAwesomeIcon>
            </div>
            <div className="bg-secondary text-white flex items-center justify-center w-8 h-8 rounded-full">
              <FontAwesomeIcon icon={faLinkedin}>
                <a
                  href="https://www.linkedin.com/company/radeusbusiness/"
                  target="_blank"
                />
              </FontAwesomeIcon>
            </div>
          </div>
        </div>
        <div className="text-meduimGrey">
          ©<span>{year}</span> Radeus, All Rights Reserved
        </div>
      </div>

      {footerItems.map(nav => (
        <div className=" " key={nav.id}>
          <h6 className="font-semibold text-tertiary mb-4">{nav.name}</h6>
          <ul>
            {nav.list.map(list => (
              <li key={list.id} className="text-meduimGrey mb-4">
                <HashLink to={list.route}>{list.name}</HashLink>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Footer;
