import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
  Navigate,
} from "react-router-dom";
import NavBar from "../components/NavBar";
import ParentContainer from "../components/parenntContainer";
import Home from "../Pages/Home";
import Company from "../Pages/Company";
import Services from "../Pages/Services";
import Products from "../Pages/Products";
import Footer from "../components/Footer";
import ContactModal from "../Pages/ContactModal";

const AppRoutes = () => {
  return (
    <Router>
      <ParentContainer>
        <Switch>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/company" element={<Company />} />
          <Route exact path="/products" element={<Products />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/contact" element={<ContactModal />} />
        </Switch>

        <Footer />
      </ParentContainer>
    </Router>
  );
};

export default AppRoutes;
