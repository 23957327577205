import React, { Fragment, useState } from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Tooltip from "./Tooltip";
import logo from "../image/lightlogo.svg";
import { navItems, navItemsRes } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faBars } from "@fortawesome/free-solid-svg-icons";
import Modal from "./Modal";
import ModalNavBar from "./ModalNavBar";

const NavBar = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 900);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const handleToggle = () => {
    setNavbarOpen(prev => !prev);
    console.log(navbarOpen);
  };
  function CustomLink({ children, to, ...props }) {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
      <div>
        <Link
          style={{
            borderBottom: match ? "1px solid white" : "none",
          }}
          to={to}
          {...props}
        >
          {children}
        </Link>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="z-10  flex items-center justify-between w-full  max-w-screen  ">
        <div>
          <img src={logo} />
        </div>
        <button
          onClick={() => handleToggle()}
          className=" block text-white lg:hidden cursor-pointer "
        >
          {navbarOpen ? "" : <FontAwesomeIcon icon={faBars} />}
        </button>
        <ul className="hidden lg:flex justify-between w-1/2 text-white font-semibold ">
          {navItems.map(nav =>
            nav.rout ? (
              <CustomLink
                to={nav.rout}
                key={nav.id}
                className="pb-2 transition-all ease-in-out duration-300 delay-75 hover:border-b border-white"
              >
                {nav.name}
              </CustomLink>
            ) : (
              <div>
                <li key={nav.id} data-tip data-for={nav.tipId}>
                  {/* <ReactTooltip
                    id={nav.tipId}
                    place="bottom"
                    effect="float"
                    type="light"
                    event="click"
                    eventOff="click"
                    delayHide={1000}
                    delayUpdate={1000}
                    clickable
                  >
                    <Tooltip list={nav.list} />
                  </ReactTooltip> */}
                  {nav.name}
                </li>
              </div>
            )
          )}
        </ul>
       {navbarOpen && <ModalNavBar navAction={navbarOpen} onClose={handleToggle} />}
      </div>
    </Fragment>
  );
};

export default NavBar;
