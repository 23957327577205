import software from "../image/software.jpg";
import frontend from "../image/backend.jpg";
import backend from "../image/frontend.jpg";
import mobile from "../image/mobile.jpg";
import kadepp from "../image/kadepp.svg";
import world from "../image/world.svg";

import {
  faAngular,
  faJava,
  faWindows,
  faApple,
  faSoundcloud,
  faAndroid,
  faReact,
} from "@fortawesome/free-brands-svg-icons";
import linx from "../image/dash.png";
import dynamo from "../image/dyn.jpeg";

export const partners = [
  {
    img: "https://images.squarespace-cdn.com/content/v1/561e4503e4b093c102b5fdb5/1444825159312-92ZLAJDUY7ZGLKUR4JG2/Large+logo_Final_orange.png?format=1500w",
    id: "1",
  },
  {
    img: world,
    id: "2",
  },
  {
    img: "https://radeusbusiness.com/wp-content/uploads/2021/12/Octoville.png",
    id: "3",
  },
  {
    img: "https://sterling.ng/wp-content/uploads/2020/04/sterling-logo-balanced.png",
    id: "4",
  },
  {
    img: "https://greenhouse.capital/wp-content/themes/struktur-child/assets/images/logo.png",
    id: "5",
  },
  {
    img: "https://efina.org.ng/wp-content/uploads/2021/09/logo.png",
    id: "6",
  },
  {
    img: "https://www.boi.ng/wp-content/uploads/2019/11/boi-logo-300.png",
    id: "7",
  },
  {
    img: "https://upload.wikimedia.org/wikipedia/commons/8/8f/Logo_for_Google_for_Startups_page.png",
    id: "8",
  },
  {
    img: "https://gc.fund/wp-content/themes/sabo/assets/img/logo.png",
    id: "9",
  },
  {
    img: "https://i0.wp.com/www.naijahomebased.com/wp-content/uploads/2019/01/Union-Bank-logo.jpg?resize=696%2C391&ssl=1",
    id: "10",
  },

  {
    img: kadepp,
    id: "11",
  },
];
export const reasons = [
  {
    title: "We value our customers",
    description:
      "We encourage collaboration by listening to and respecting our customers' feedback.",
    id: "1",
  },
  {
    title: "Deep level of expertise",
    description:
      "When you collaborate with Radeus, you are bringing in top talent to your project. Our developers are highly skilled and knowledgeable in meeting your business objective.",
    id: "2",
  },
  {
    title: "Highest level of System",
    description:
      "We reduce risk, while ensuring the highest level of system and data security.",
    id: "3",
  },
  {
    title: "Structure and Competence",
    description:
      "Our pricing is determined by your budget. From the beginning to the end of your project, we adapt to you and stay to the cost we agreed upon.",
    id: "4",
  },
];
export const figures = [
  {
    number: 10,
    description: "Satisfied Clients",
    id: "1",
  },
  {
    number: 30,
    description: "Cutting edge Solutions",
    id: "2",
  },
  {
    number: 50,
    description: "Trainings",
    id: "3",
  },
  {
    number: 2,
    description: "Products",
    id: "4",
  },
];
export const navItems = [
  { name: "Home", id: "1", rout: "/", tipId: "company", list: [] },
  {
    name: "Company",
    id: "2",
    tipId: "company",
    rout: "/company",

    list: [
      { name: "Customers", id: "5", route: "/customer" },
      { name: "Careers", id: "6", route: "/careers" },
      { name: "Vision/Mission", id: "7", route: "/vision" },
      { name: "Our Team", id: "8", route: "/team" },
      { name: "Build A Custom App", id: "9", route: "/build" },
      { name: "Be a Partner", id: "10", route: "/partner" },
    ],
  },
  {
    name: "Products",
    id: "3",
    tipId: "products",
    rout: "/products",
    list: [
      { name: "Linx", id: "1", route: "/linx" },
      { name: "Dynamo", id: "2", route: "/dynamo" },
    ],
  },
  {
    name: "Services",
    id: "4",
    tipId: "services",
    rout: "/services",

    list: [
      { name: "Custom Software Development", id: "1", route: "/linx" },
      { name: "Backend Implementation", id: "2", route: "/dynamo" },
      { name: "Front-End Implementation", id: "3", route: "/dynamo" },
      { name: "Mobile App Development", id: "4", route: "/dynamo" },
      { name: "Cloud App Development", id: "5", route: "/dynamo" },
      { name: "Software Integration", id: "6", route: "/dynamo" },
      { name: "DevOps Services", id: "7", route: "/dynamo" },
    ],
  },
];
export const footerItems = [
  {
    name: "Get Started",
    id: "1",

    list: [
      { name: "Services", id: "6", route: "/services" },
      { name: "Platforms", id: "7", route: "/products" },
      { name: "Contact Us", id: "8", route: "/contact" },
    ],
  },
  {
    name: "Company",
    id: "2",

    list: [
      { name: "About Us", id: "5", route: "/company/#about" },
      { name: "Partners", id: "6", route: "/#partners" },
      { name: "Our Stack", id: "7", route: "/company/#stack" },
    ],
  },
  {
    name: "Products",
    id: "3",

    list: [
      { name: "Linx", id: "1", route: "/products/#linx" },
      { name: "Dynamo", id: "2", route: "/products/#dynamo" },
    ],
  },
];
export const navItemsRes = [
  {
    name: "Company",
    id: "2",

    list: [
      { name: "About Us", id: "5", route: "/about" },
      { name: "Careers", id: "6", route: "/careers" },
      { name: "Vision/Mission", id: "7", route: "/vision" },
      { name: "Our Team", id: "8", route: "/team" },
      { name: "Build A Custom App", id: "9", route: "/build" },
      { name: "Be a Partner", id: "10", route: "/partner" },
    ],
  },
  {
    name: "Products",
    id: "3",

    list: [
      { name: "Linx", id: "11", route: "#linx" },
      { name: "Dynamo", id: "12", route: "#dynamo" },
    ],
  },
  {
    name: "Services",
    id: "4",

    list: [
      { name: "Custom Software Development", id: "11", route: "/linx" },
      { name: "Backend Implementation", id: "13", route: "/dynamo" },
      { name: "Front-End Implementation", id: "14", route: "/dynamo" },
      { name: "Mobile App Development", id: "15", route: "/dynamo" },
      { name: "Cloud App Development", id: "16", route: "/dynamo" },
      { name: "Software Integration", id: "17", route: "/dynamo" },
      { name: "DevOps Services", id: "18", route: "/dynamo" },
    ],
  },
];
export const size = [
  {
    title: "250M+",
    description:
      "From direct integrations with card networks and banks to checkout flows in the browser",
    id: "1",
  },
  {
    title: "90%",
    description:
      "We release hundreds of features and improvements each year to help you stay ahead ",
    id: "2",
  },
];

export const stack = [
  {
    icon: faAngular,
    title: "ANGULAR",
    des: "Angular, Ionic, Electron, Angular, CLI, Gulp, Webpack",
    id: "1",
  },
  {
    icon: faJava,
    title: "JAVA",
    des: "Core Java/JEE, Hibernate, Cloud, Spring Framework, Security",
    id: "2",
  },
  {
    icon: faWindows,
    title: ".NET",
    des: "ASP.NET, Azure, Visual Studio, Power BI, Band SDK, Sharepoint",
    id: "3",
  },
  {
    icon: faApple,
    title: "NATIVE iOS",
    des: "RxSwift, Object C, Snapkit, Apple Design Style",
    id: "4",
  },
  {
    icon: faSoundcloud,
    title: "CLOUD",
    des: "Google, AWS, Azure, Hybrid and On-Premise Cloud",
    id: "5",
  },
  {
    icon: faAndroid,
    title: "NATIVE ANDROID",
    des: "Kotlin, RxJava, Moxy, Components,",
    id: "6",
  },
  {
    icon: faReact,
    title: "REACT",
    des: "React, Redux, Context API,styled Components,",
    id: "7",
  },
];

export const services = [
  {
    name: "Custom Software Development",
    description:
      "We build top-notch bespoke platforms for different businesses ranging from Small to Large enterprise. We offer a personalized experience that will establish a strong tie between your company and its clients.",
    id: "1",
    type: "white",
    img: software,
  },
  {
    name: "Backend Implementation",
    description:
      "We handle your back-end needs, including architecture, integration, and API layer development, as well as testing, backup, and debugging. We also specialize in decoupling into micro-services.",
    id: "2",
    type: "blue",
    img: backend,
  },
  {
    name: "Front-End Implementation",
    description:
      "We work with a variety of frontend technologies to make your frontend look great, be efficient, and be aesthetically pleasing. We also help with web experience transformation of your legacy apps.",
    id: "3",
    type: "white",
    img: frontend,
  },
  {
    name: "Mobile App Development",
    description:
      "Do you require high-quality mobile apps for your startup, industrial hub, multinational organization, or bank? We can design almost anything with a highly tailored approach to your demands and on any platform - iOS, Android, or anything hybrid.",
    id: "4",
    type: "blue",
    img: mobile,
  },
  {
    name: "Cloud App Development",
    description:
      "Cloud computing can undoubtedly help you save time, money, and effort. Our  cloud development services, include cloud migration, integration, testing, SaaS development, microservices decomposition, and more.",
    id: "5",
    type: "white",
    img: software,
  },
  {
    name: "Software Integration",
    description:
      "We are experts at connecting the dots and take delight in providing high-quality integration services. If you require enterprise app integration, OpenAPI implementation, integration layer development, or integration testing, please contact us.",
    id: "6",
    type: "blue",
    img: frontend,
  },
];
export const why = [
  {
    description:
      "Teamwork is always better. We encourage it by listening and valuing our customers’ ideas",
    id: "1",
  },

  {
    description:
      "We reduce risk, while ensuring the highest level of system and data security ",
    id: "2",
  },
  {
    description:
      "Work with committed team of software engineers with a deep level of expertise      ",
    id: "3",
  },
  {
    description:
      "It’s easy to do business with us. We bring you structure and competence.",
    id: "4",
  },
];
export const products = [
  {
    name: "Linx",
    description:
      "LinX is a Web based Business Operating System (BOS) with integrated components that allows businesses of different levels (SMEs - Small and Medium Enterprises) to manage the whole spectrum of their business(es) by accessing different utilities, business services and more from a centralized platform.",
    id: "linx",
    type: "white",
    link: "https://linxplatform.com/",
    img: linx,
  },
  {
    name: "Dynamo",
    description:
      "Customer data and information is key to any business for decision making, and it's important to have a tool that allows seamless collection and aggregation. Dynamo allows for collection of clean data for different purpose with user defined forms and verification process.",
    id: "dynamo",
    type: "blue",
    link: "",
    img: dynamo,
  },
];
