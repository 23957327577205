import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import { products } from "../components/utils";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import animationData from "../lottie/products.json";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import dash from "../image/dash.png";
import { Slide } from "react-reveal";

const Products = () => {
  const { text } = useTypewriter({
    words: ["PRODUCTS", "PRODUCTS", "PRODUCTS"],
    loop: {},
    cursor: true,
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="relative ">
      <Lottie
        animationData={animationData}
        autoPlay
        loop
        className="absolute hidden md:block -right-10 top-20 w-2/5 z-10"
      />
      <div className="relative flex flex-col gap-20 justify-center h-screen/2 md:h-screen/ w-screen clip-path-mypolygonSmall lg:clip-path-mypolygon bg-secondary py:4 md:py-10 px-10 lg:px-52 md:px-32 overflow-hidden ">
        <div className="absolute top-0 left-0 w-screen max-w-full pt-8  px-10 lg:px-52 md:px-32  z-20 ">
          <NavBar />
        </div>
        <div className="animate-glow mb-6 md:mb-10 text-3xl md:text-5xl lg:text-7xl uppercase text-white font-bold  ">
          {text} <Cursor />
        </div>
      </div>
      <div className="-mt-36 z-20 static ">
        {products.map(serv =>
          serv.type === "blue" ? (
            <div key={serv.id} id={serv.id}>
              <div className=" gap-8 flex flex-col md:flex-row h-auto w-screen clip-path-myslant bg-secondary py-52 px-10 lg:px-52 md:px-32">
                <div className="w-full md:w-1/2 flex flex-col gap-8 ">
                  <h1 className="text-white font-bold text-2xl md:text-4xl ">
                    {serv.name}
                  </h1>
                  <Slide left>
                    <p className="text-softGrey">{serv.description}</p>
                  </Slide>
                  <div className="flex items-center justify-center md:items-start md:justify-start">
                    <button className="bg-skyBlue text-1xl text-secondary rounded-full px-7 py-3 my-5">
                      <a href={serv.link} target="_blank">
                        {" "}
                        Get Started
                      </a>
                    </button>
                  </div>
                </div>
                <Slide right>
                  <div className="w-full md:w-1/2 h-64 object-cover bg-secondary shadow-inner shadow-white p-1 rounded-md">
                    <img
                      src={serv.img}
                      loading="lazy"
                      className="w-full h-full bg-inherit hover:opacity-60 hover:bg-blend-multiply"
                    />
                  </div>
                </Slide>
              </div>
            </div>
          ) : (
            <div key={serv.id} id={serv.id}>
              <div className="gap-8 flex flex-col md:flex-row-reverse h-auto w-screen   py-52 px-10 lg:px-52 md:px-32">
                <div className="w-full md:w-1/2 flex flex-col gap-8">
                  <h1 className="text-secondary font-bold text-2xl md:text-4xl ">
                    {serv.name}
                  </h1>
                  <Slide left>
                    <p className="text-meduimGrey">{serv.description}</p>
                  </Slide>
                  <div className="flex items-center justify-center md:items-start md:justify-start">
                    <button className="bg-meduimGrey text-1xl text-white rounded-full px-7 py-3 my-5">
                      <a href={serv.link} target="_blank">
                        {" "}
                        Get Started
                      </a>
                    </button>
                  </div>
                </div>
                <Slide right>
                  <div className="w-full md:w-1/2 h-64 object-cover bg-white shadow-inner shadow-meduimGrey p-1 rounded-md">
                    <img
                      src={serv.img}
                      loading="lazy"
                      className="w-full h-full bg-inherit hover:opacity-60 hover:bg-blend-multiply"
                    />
                  </div>
                </Slide>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Products;
