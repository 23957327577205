import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./routes/AppRoutes";
import Home from "./Pages/Home";
import NavBar from "./components/NavBar";
import ContactModal from "./Pages/ContactModal";
import { BrowserRouter } from "react-router-dom";

function App() {
  return <AppRoutes />;
}

export default App;
